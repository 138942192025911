import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';

import { FixtureLinkButton } from './FixtureLinkButton';
import { FixtureMatchReportLink } from './FixtureMatchReportLink';
import { FixtureCountContext } from '../../fixtures-list/FixturesList';

export const FixtureLinks = ({
    match,
    matchStatus,
    metadata = {},
    broadcastGuideUrl,
    reportLabel,
    index
}) => {
    const hasFreeEntry = metadata.free_entry === 'true' ? true : false,
        isSoldOut = metadata.sold_out === 'true' ? true : false,
        cardsCount = useContext(FixtureCountContext);

    const renderUpcomingTicketLinks = () => {
        let ticketLink = {};

        if (metadata.ticket_link && !(hasFreeEntry || isSoldOut)) {
            ticketLink = {
                icon: 'tickets-new',
                label: getTranslation('label.fixtures.buy-tickets'),
                href: metadata.ticket_link,
                target: '_blank',
                cssClass: 'fixtures__link-button--alt'
            };

            return (
                <FixtureLinkButton
                    {...ticketLink}
                    match={match}
                    onClick={() => {
                        PULSE.app.tracking.selectFixtureCardHandler(
                            match,
                            getTranslation('label.fixtures.buy-tickets'),
                            metadata.ticket_link,
                            index,
                            cardsCount
                        );
                    }}
                />
            );
        }
    };

    const renderUpcomingOrLiveTicketLinks = () => {
        let broadcastGuideLink = {};
        let playerStatsLink = {};

        // only show a broadcast guide link if the match is assigned to a broadcaster match-event
        if (
            broadcastGuideUrl &&
            matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE
        ) {
            broadcastGuideLink = {
                icon: 'live-broadcast',
                label: getTranslation('label.fixtures.broadcastGuide'),
                href: broadcastGuideUrl,
                target: '_blank',
                theme: 'live'
            };

            playerStatsLink = {
                icon: 'player-stats',
                label: getTranslation('label.fixtures.player-stats'),
                href:
                    PULSE.app.common.url.getMatchURL(match.id) +
                    '#player-stats',
                target: '_blank',
                theme: 'live'
            };

            return (
                <>
                    <FixtureLinkButton
                        {...playerStatsLink}
                        match={match}
                        onClick={() => {
                            PULSE.app.tracking.selectFixtureCardHandler(
                                match,
                                getTranslation('label.fixtures.player-stats'),
                                playerStatsLink?.href,
                                index,
                                cardsCount
                            );
                        }}
                    />
                    <FixtureLinkButton
                        {...broadcastGuideLink}
                        match={match}
                        onClick={() => {
                            PULSE.app.tracking.selectFixtureCardHandler(
                                match,
                                getTranslation('label.fixtures.broadcastGuide'),
                                broadcastGuideUrl,
                                index,
                                cardsCount
                            );
                        }}
                    />
                </>
            );
        }

        // only show a broadcast guide link if the match is assigned to a broadcaster match-event
        if (
            broadcastGuideUrl &&
            matchStatus !== PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE
        ) {
            broadcastGuideLink = {
                icon: 'live-broadcast',
                label: getTranslation('label.fixtures.broadcastGuide'),
                href: broadcastGuideUrl,
                target: '_blank'
            };

            return (
                <FixtureLinkButton
                    {...broadcastGuideLink}
                    match={match}
                    onClick={() => {
                        PULSE.app.tracking.selectFixtureCardHandler(
                            match,
                            getTranslation('label.fixtures.broadcastGuide'),
                            broadcastGuideUrl,
                            index,
                            cardsCount
                        );
                    }}
                />
            );
        }

        // show Player Stats button if no broadcast guide, stream exists and match is live
        if (
            !broadcastGuideUrl &&
            matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE
        ) {
            playerStatsLink = {
                icon: 'player-stats',
                label: getTranslation('label.fixtures.player-stats'),
                href:
                    PULSE.app.common.url.getMatchURL(match.id) +
                    '#player-stats',
                target: '_blank',
                theme: 'live'
            };

            return (
                <FixtureLinkButton
                    {...playerStatsLink}
                    match={match}
                    onClick={() => {
                        PULSE.app.tracking.selectFixtureCardHandler(
                            match,
                            getTranslation('label.fixtures.player-stats'),
                            playerStatsLink?.href,
                            index,
                            cardsCount
                        );
                    }}
                />
            );
        }
    };

    const renderUpcomingTravelLinks = () => {
        let travelLink = {};

        if (metadata.travel_link) {
            travelLink = {
                icon: 'plane',
                label: getTranslation('label.fixtures.travel'),
                href: metadata.travel_link,
                target: '_blank'
            };

            return (
                <FixtureLinkButton
                    {...travelLink}
                    match={match}
                    onClick={() => {
                        PULSE.app.tracking.selectFixtureCardHandler(
                            match,
                            getTranslation('label.fixtures.travel'),
                            metadata?.travel_link,
                            index,
                            cardsCount
                        );
                    }}
                />
            );
        }
    };

    return (
        <>
            {matchStatus ===
                PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED && ( // check match status
                <FixtureMatchReportLink
                    match={match}
                    reportLabel={reportLabel}
                />
            )}

            {matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING &&
                renderUpcomingTicketLinks()}

            {(matchStatus ===
                PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING ||
                matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE) &&
                renderUpcomingOrLiveTicketLinks()}

            {matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING &&
                renderUpcomingTravelLinks()}
        </>
    );
};

FixtureLinks.propTypes = {
    match: PropTypes.object,
    matchStatus: PropTypes.string,
    metadata: PropTypes.object,
    reportLabel: PropTypes.string,
    broadcastGuideUrl: PropTypes.string,
    index: PropTypes.number
};
