/* eslint-disable react/prop-types */
/* eslint-disable id-length */
/* eslint-disable max-lines */
/**
 * Use a class component to get access to lifecycle methods, otherwise use a functional component.
 * Should only contain rendering logic; business logic should be contained to Redux Sagas.
 */

import React from 'react';
import cx from 'classnames';

import TableHead from './TableHead';
import TableTitle from './TableTitle';
import TableRow, { getVisibilityCondition } from './TableRow';
import TotalTableRow from './TotalTableRow';
import TableLegends from './TableLegends';

class DefaultCustomTable extends React.Component {
    constructor(props) {
        super(props);

        // Set initial states
        this.state = {
            modalOpen: false,
            displayedFields: [],
            tableFieldGroups: {},
            localStorageFields: [],
            manageFieldsBar: {
                left: '0'
            },
            manageFieldsBarDisplayed: true
        };

        this.lastColumnRef = React.createRef();
        this.customTableRef = React.createRef();
        this.scrollableTable = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (this.props.customTable) {
            // Updating the table with data from local storage
            if (
                (this.props.customTable.tableName && !prevProps.customTable) ||
                this.props.customTable.tableName !==
                    prevProps.customTable.tableName
            ) {
                if (!this.props.customTable.fields) {
                    return;
                }

                const localStorageRef = localStorage.getItem(
                    this.props.customTable.tableName
                );
                let displayedFields;

                if (localStorageRef) {
                    /*
                        Serialising/deserialising JSON will lose the customCellRenderer and sortFunction attributes,
                        so we'll copy those over from the original settings.
                     */

                    displayedFields = JSON.parse(localStorageRef).map(
                        (localStorageField) => {
                            const configField =
                                this.props.customTable.fields.find(
                                    (existingField) =>
                                        existingField.fieldName ===
                                        localStorageField.fieldName
                                );

                            if (configField) {
                                // base it off the original config settings, but update with the values from localStorage
                                return {
                                    ...configField,
                                    ...localStorageField
                                };
                            }
                            return localStorageField;
                        }
                    );

                    const newState = {
                        displayedFields,
                        tableFieldGroups: this.props.customTable.fieldGroups
                    };

                    this.setState(newState);

                    this.updateManageFieldsPosition();

                    this.props.updateDisplayedCustomTableFields(
                        this.props.customTable.tableName,
                        newState
                    );
                }

                const localStorageSort = localStorage.getItem(
                    `${this.props.customTable.tableName}_sort`
                );

                if (localStorageSort && displayedFields) {
                    const localStorageData = JSON.parse(localStorageSort);
                    const additionalSortProps =
                        localStorageData.additionalSortProps || null;

                    const fieldDisplayed = displayedFields.filter(
                        (field) =>
                            field.fieldName === localStorageData.field.fieldName
                    );

                    if (fieldDisplayed && fieldDisplayed.length) {
                        this.handleClick(
                            localStorageData.field,
                            this.props.customTable.tableName,
                            localStorageData.sortDirection,
                            additionalSortProps
                        );
                    }
                }
            }
        }

        if (this.lastColumnRef && this.lastColumnRef.current !== null) {
            let offsetRight =
                this.lastColumnRef.current.getBoundingClientRect().right;
            const offsetLeftValue = offsetRight + 12;
            let offsetLeft = offsetLeftValue + 'px';

            if (offsetLeftValue > window.innerWidth) {
                offsetLeft = 'calc( 100% - 72px )';
            }

            if (offsetLeft !== this.state.manageFieldsBar.left) {
                this.updateManageFieldsPosition();
            }
        }
    }

    componentDidMount() {
        document.addEventListener(
            PULSE.app.common.CONSTANTS.EVENTS.TABS.TRIGGERED,
            () => {
                this.updateManageFieldsPosition();
            }
        );
    }

    handleClick = (field, tableName, sortDirection, additionalSortProps) => {
        /*
            When the user clicks a column, fire an action to sort the given table by that column.
            The action will trigger a saga, the saga will trigger a DELTA action to update the Redux store with the new column sort data.

            Updating the store will cause the "sortedTableData" prop to return different data (as its now sorted differently),
            which will cause this table component to re-render
        */

        const sortLocalStorage = {
            sortDirection: sortDirection,
            field: field,
            additionalSortProps: additionalSortProps
        };

        localStorage.setItem(
            `${tableName}_sort`,
            JSON.stringify(sortLocalStorage)
        );

        this.props.sortCustomTable(
            tableName,
            field,
            sortDirection,
            additionalSortProps
        );
    };

    toggleModal = (tableName) => {
        this.props.toggleModal(tableName);
    };

    // Gets translation if we dont mind the label being retured ie. Table Header that may not be translation
    getTranslation = (text) => {
        return PULSE.I18N.lookup(text);
    };

    getFallbackTranslation = (text, fallback, className) => {
        // Get the translation
        const translation = PULSE.I18N.lookup(text);
        // Check if the translation = the text (ie. no translation found)
        let returnTranslation = translation === text ? fallback : translation;

        if (returnTranslation.includes('|')) {
            const newClass = className ? className : '';
            let translationTextArray = returnTranslation.split('|');

            // Ensure where you are rendering this out you use dangerouslySetInnerHTML on the element else it will render span as string
            returnTranslation = `${translationTextArray[0]} <span className="${newClass}">${translationTextArray[1]}</span>`;
        }

        return returnTranslation;
    };

    updateManageFieldsPosition = () => {
        if (this.lastColumnRef && this.lastColumnRef.current !== null) {
            let offsetRight =
                this.lastColumnRef.current.getBoundingClientRect().right;
            const offsetLeftValue = offsetRight + 12;
            let offsetLeft = offsetLeftValue + 'px';

            if (offsetLeftValue > window.innerWidth) {
                offsetLeft = 'calc( 100% - 72px )';
            }

            const manageFieldsBar = {
                left: offsetLeft
            };

            this.setState({ manageFieldsBar });
        }
    };

    toggleMangageFieldsBar = (tableName) => {
        this.props.toggleMangageFieldsBar(tableName);
    };

    setDescriptionShowing = (event, field) => {
        const elementHasClass = event.target.classList.contains('js-close-btn');
        const parentElement = event.target.closest('.js-close-btn');

        if (!elementHasClass && !parentElement) {
            this.setState({ descriptionShowing: field.fieldName });
        } else {
            this.setState({ descriptionShowing: '' });
        }
    };

    getDescriptionShowing = (field) => {
        return field.fieldName === this.state.descriptionShowing;
    };

    bodyClick = (event) => {
        const elementHasClass = event.target.classList.contains(
            'js-stats-item-description'
        );
        const parentElement = event.target.closest(
            '.js-stats-item-description'
        );

        if (!elementHasClass && !parentElement) {
            this.setState({ descriptionShowing: '' });
        } else {
            return;
        }
    };

    closeDescription = () => {
        this.setState({ descriptionShowing: '' });
    };

    isScrolling = () => {
        this.setState({ eventListenerAdded: true });

        if (
            this.scrollableTable &&
            this.scrollableTable.current &&
            this.scrollableTable.current.scrollLeft > 0
        ) {
            this.setState({ isScrolling: true });
        } else {
            this.setState({ isScrolling: false });
        }
    };

    // Check for visibility condition functions and fire any found
    filteredCustomTableFields = () => {
        return this.props.displayedCustomTableFields.filter((field) => {
            if (typeof field.visibiltyConditionFunc !== 'string') {
                return true;
            }

            return getVisibilityCondition(field.visibiltyConditionFunc)(
                this.props.customTable.dataJson
            );
        });
    };

    render() {
        const isLoading =
            typeof this.props.isLoading === 'undefined'
                ? !this.props.customTable ||
                  (!this.props.customTable.fields &&
                      !this.props.customTable.failed)
                : this.props.isLoading;

        if (isLoading) {
            return (
                <div className="list-loader">
                    <div
                        className="list-loader__icon"
                        dangerouslySetInnerHTML={{
                            __html: PULSE.app.templating.render(
                                {
                                    cssClass: '',
                                    name: 'loading-dark'
                                },
                                'common.svg-icon'
                            )
                        }}
                    ></div>
                    Loading
                </div>
            );
        } else if (this.props.customTable && this.props.customTable.failed) {
            return (
                <div className="custom-table">
                    <div className="empty-state">
                        <div className="empty-state__background"></div>
                        <div className="empty-state__message">
                            <h3 className="empty-state__message-label">
                                {this.getTranslation(
                                    'label.defaultTable.emptyState.title'
                                )}
                            </h3>
                            <span className="empty-state__summary">
                                {this.getTranslation(
                                    'label.defaultTable.emptyState.summary'
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            );
        }

        if (
            this.scrollableTable &&
            this.scrollableTable.current &&
            !this.state.eventListenerAdded
        ) {
            this.scrollableTable.current.addEventListener(
                'scroll',
                this.isScrolling
            );
        }

        const hasTable =
            this.props.customTable && this.props.customTable.tableName
                ? true
                : false;
        const tableClass = hasTable
            ? `custom-table--${this.props.customTable.tableName}`
            : '';
        const hasTotalRow =
            this.props.customTable &&
            this.props.customTable.config &&
            this.props.customTable.config.totalTableRow;
        const isScrolling = this.state.isScrolling ? true : false;

        const totalTableRow = hasTotalRow ? (
            <TotalTableRow
                key={'tableRow_totalRow'}
                state={this.state}
                sortedTableData={this.props.sortedTableData}
                totalTableRows={this.props.totalRows}
                customTable={this.props.customTable}
                fields={this.props.customTable.fields}
                displayedFields={this.filteredCustomTableFields()}
                getTranslation={this.getTranslation}
            />
        ) : null;

        return (
            <div
                ref={this.customTableRef}
                className={cx('custom-table js-custom-table wrapper', {
                    [tableClass]: hasTable
                })}
            >
                <div className="custom-table__saved-device-text-container u-hide-until-tablet">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: PULSE.app.templating.render(
                                {
                                    cssClass: '',
                                    name: 'info'
                                },
                                'common.svg-icon'
                            )
                        }}
                    ></span>
                    <p
                        className="custom-table__saved-device-text"
                        dangerouslySetInnerHTML={{
                            __html: this.getFallbackTranslation(
                                'label.customTable.savedDeviceText',
                                `${this.props.customTable.config.infoText} | will only be saved on this device`
                            )
                        }}
                    ></p>
                </div>

                {[...Array(this.props.customTable.multipleTablesLength)].map(
                    (x, multipleTableIndex) => (
                        <div
                            className="custom-table__table-wrapper"
                            key={multipleTableIndex}
                        >
                            <div
                                className={`custom-table__manage-fields-bar u-hide-until-phablet ${
                                    this.props.manageFieldsBarShowing === true
                                        ? ''
                                        : 'u-hide'
                                }`}
                                style={{
                                    left: this.state.manageFieldsBar.left
                                }}
                            >
                                <button
                                    className="custom-table__manage-fields-bar-button"
                                    onClick={() =>
                                        this.toggleModal(
                                            this.props.customTable.tableName
                                        )
                                    }
                                >
                                    <span className="custom-table__manage-fields-bar-button-icon"></span>
                                </button>
                                <h4 className="custom-table__manage-fields-bar-title">
                                    {this.getFallbackTranslation(
                                        `label.customTable.${this.props.data.tablename}.table.manageFields`,
                                        'Manage Fields'
                                    )}
                                </h4>
                                <button
                                    className="custom-table__manage-fields-bar-button-hide"
                                    onClick={() =>
                                        this.toggleMangageFieldsBar(
                                            this.props.data.tablename
                                        )
                                    }
                                >
                                    {this.getFallbackTranslation(
                                        `label.customTable.${this.props.data.tablename}.table.manageFields.hide`,
                                        'HIDE'
                                    )}
                                </button>
                            </div>

                            {
                                <TableTitle
                                    key={`table_title_${multipleTableIndex}`}
                                    title={
                                        this.props.customTable.tableTitles[
                                            multipleTableIndex
                                        ]
                                    }
                                />
                            }

                            <div
                                ref={this.scrollableTable}
                                className={cx(
                                    'custom-table__table-container js-scrolling-area',
                                    {
                                        'is-scrolling': isScrolling
                                    }
                                )}
                            >
                                <table className="custom-table__table">
                                    <thead>
                                        <tr className="custom-table__row custom-table__row--header">
                                            {this.filteredCustomTableFields().map(
                                                (field, i) => (
                                                    <TableHead
                                                        key={`table_head_${i}`}
                                                        field={field}
                                                        multipleTableIndex={
                                                            multipleTableIndex
                                                        }
                                                        tableName={
                                                            this.props.data
                                                                .tablename
                                                        }
                                                        sortBy={
                                                            this.props.sortBy ||
                                                            this.props
                                                                .customTable
                                                                .sortBy
                                                        }
                                                        handleClick={
                                                            this.handleClick
                                                        }
                                                        getTranslation={
                                                            this.getTranslation
                                                        }
                                                        lastColumn={
                                                            this.filteredCustomTableFields()
                                                                .length -
                                                                1 ===
                                                            i
                                                                ? true
                                                                : false
                                                        }
                                                        lastColumnRef={
                                                            this.lastColumnRef
                                                        }
                                                    />
                                                )
                                            )}
                                            <th
                                                key="table_head_empty_column"
                                                className={`custom-table__header-cell custom-table__header-cell--empty ${
                                                    this.state
                                                        .manageFieldsBarDisplayed ===
                                                    true
                                                        ? ''
                                                        : 'u-hide'
                                                }`}
                                            ></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="custom-table__spacer-row">
                                            <td
                                                colSpan={`${this.props.customTable.displayedFields.length}`}
                                            ></td>
                                        </tr>
                                        {this.props.sortedTableData.map(
                                            (tableRow, i) => {
                                                return tableRow ? (
                                                    <TableRow
                                                        key={
                                                            tableRow
                                                                ? tableRow.rowIdentifier
                                                                : `tableRow_${i}`
                                                        }
                                                        state={this.state}
                                                        tableRow={tableRow}
                                                        multipleTableIndex={
                                                            multipleTableIndex
                                                        }
                                                        customTable={
                                                            this.props
                                                                .customTable
                                                        }
                                                        fields={
                                                            this.props.fields ||
                                                            this.props
                                                                .customTable
                                                                .fields
                                                        }
                                                        displayedFields={
                                                            this.props
                                                                .displayedCustomTableFields
                                                        }
                                                        getTranslation={
                                                            this.getTranslation
                                                        }
                                                        config={
                                                            this.props.config ||
                                                            this.props
                                                                .customTable
                                                                .config
                                                        }
                                                    />
                                                ) : null;
                                            }
                                        )}

                                        {totalTableRow}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                )}

                <TableLegends
                    key="table-legends"
                    state={this.state}
                    displayedFields={this.filteredCustomTableFields()}
                    fields={this.props.fields || this.props.customTable.fields}
                    getTranslation={this.getTranslation}
                />
            </div>
        );
    }
}

export default DefaultCustomTable;
