import { useCallback, useState, useEffect } from 'react';

/**
 * Filters tracking custom hook
 *
 * @param {object} props
 * @param {string} props.title Title of the section the filters are nested in. Will be sent as `dataLayer.eventData.filter_name`
 * @param {object} props.filtersState navSelector object
 * @param {object} props.options value of useCompetitionNavigationOptions hook
 * @param {Array} [props.filtersFields] Array of strings representing filters to be tracked
 * @param props.filtersRef
 */
export const useFiltersTracking = ({
    title,
    filtersState,
    options = {},
    filtersFields = ['competition', 'season', 'round'],
    filtersRef
}) => {
    const [seasonId, setSeasonId] = useState();
    const [canTrack, setCanTrack] = useState(false);

    /**
     * getting values for the tracked filters
     * and creating arrays of types and resolved values
     */
    const convertedValues = useCallback(() => {
        return PULSE.app.analytics.convertFiltersValues(
            filtersFields,
            filtersState,
            options,
            seasonId
        );
    }, [filtersState, options]);

    /**
     * invoking tracking of the filters
     */
    const trackFilters = useCallback(() => {
        PULSE.app.tracking.applyFilters({
            name: title,
            ...convertedValues()
        });
    }, [filtersState]);

    const onFiltersInteracted = () => {
        setCanTrack(true);
    };

    /**
     * update seasonId in local state
     * seasonId is needed for resolving team and venue names
     */
    useEffect(() => {
        if (filtersState?.season?.id) {
            setSeasonId(filtersState.season.id);
        }
    }, [filtersState]);

    useEffect(() => {
        if (filtersRef?.current) {
            filtersRef.current.addEventListener(
                PULSE.app.common.CONSTANTS.EVENTS.INTERACTION.CHANGE,
                onFiltersInteracted
            );
        }

        return () => {
            filtersRef?.current.removeEventListener(
                PULSE.app.common.CONSTANTS.EVENTS.INTERACTION.CHANGE,
                onFiltersInteracted
            );
        };
    }, [filtersRef?.current]);

    useEffect(() => {
        if (canTrack) {
            window.addEventListener('beforeunload', trackFilters);
        }

        return () => {
            window.removeEventListener('beforeunload', trackFilters);
        };
    }, [filtersState, options, canTrack]);
};
