/* eslint-disable max-lines, react/prop-types */
/**
 * Use a class component to get access to lifecycle methods, otherwise use a functional component.
 * Should only contain rendering logic; business logic should be contained to Redux Sagas.
 */

import React from 'react';

import DefaultTable from 'custom-table/js/components/DefaultTable';

import { numberCompare } from 'custom-table/js/helpers/sort';

class PlayerStatsTable extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        // Testing config
        const tableConfig = {
            tableName: 'playerStats',
            groups: [
                {
                    name: 'Player',
                    label: 'Player',
                    isPersistent: true,
                    fields: [
                        {
                            fieldName: 'jumperNumber',
                            fieldLabel: '#',
                            fieldLabelShort: '#',
                            sortFunction: 'jumperCompare',
                            apiMap: { match: 0, season: 1 },
                            customCellRenderer: 'PlayerJumperCellRenderer',
                            tableHeadCustomClass:
                                'custom-table__header-cell--fixed-jumper',
                            displayByDefault: true,
                            selector: {
                                match: {
                                    playerId: 'playerId',
                                    jumperNumber: 'playerDetails.jumperNumber'
                                },
                                season: {
                                    playerId: 'player.playerId',
                                    jumperNumber: 'player.jumper'
                                }
                            },
                            parentSelector: 'players|lists',
                            isPersistent: true
                        },
                        {
                            fieldName: 'givenName',
                            fieldLabel: 'label.stats.playerName',
                            fieldLabelShort: 'label.stats.playerName.short',
                            customCellRenderer: 'PlayerCellRenderer',
                            tableHeadCustomClass:
                                'custom-table__header-cell--fixed-player',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: true,
                            sortFunction: 'surnameCompare',
                            selector: {
                                match: {
                                    givenName: 'playerDetails.givenName',
                                    surname: 'playerDetails.surname'
                                },
                                season: {
                                    givenName: 'player.givenName',
                                    surname: 'player.surname'
                                }
                            },
                            parentSelector: 'players|lists',
                            isPersistent: true
                        }
                    ]
                },
                {
                    name: 'General',
                    label: 'General',
                    fields: [
                        {
                            fieldName: 'ratingPoints',
                            fieldLabel: 'label.stats.rating',
                            fieldLabelShort: 'label.stats.rating.short',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.ratingPoints',
                                season: 'stats.averages.ratingPoints'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'freesAgainst',
                            fieldLabel: 'label.stats.freeAgainst',
                            fieldLabelShort: 'label.stats.freeAgainst.short',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.freesAgainst',
                                season: 'stats.averages.freesAgainst'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'bounces',
                            fieldLabel: 'label.stats.bounces',
                            fieldLabelShort: 'label.stats.bounces.short',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.bounces',
                                season: 'stats.averages.bounces'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'timeOnGroundPercentage',
                            fieldLabel: 'label.stats.timeOnGround',
                            fieldLabelShort: 'label.stats.timeOnGround.short',
                            description:
                                'label.timeOnGroundPercentage.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.timeOnGroundPercentage',
                                season: 'stats.timeOnGroundPercentage'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'freesFor',
                            fieldLabel: 'label.stats.freeFor',
                            fieldLabelShort: 'label.stats.freeFor.short',
                            description: 'label.freesFor.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.freesFor',
                                season: 'stats.averages.freesFor'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'dreamTeamPoints',
                            fieldLabel: 'label.mc.stats.dreamteampoints',
                            fieldLabelShort:
                                'label.mc.stats.dreamteampoints.short',
                            description: 'label.dreamTeamPoints.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.dreamTeamPoints',
                                season: 'stats.averages.dreamTeamPoints'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'onePercenters',
                            fieldLabel: 'label.stats.onePercenters',
                            fieldLabelShort: 'label.stats.onePercenters.short',
                            description: 'label.onePercenters.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.onePercenters',
                                season: 'stats.averages.onePercenters'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'gamesPlayed',
                            fieldLabel: 'label.stats.matchesPlayed',
                            fieldLabelShort: 'label.stats.matchesPlayed.short',
                            description: 'label.gamesPlayed.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'gamesPlayed',
                                season: 'stats.gamesPlayed'
                            },
                            parentSelector: 'players|lists'
                        }
                    ]
                },
                {
                    name: 'Disposals',
                    label: 'Disposals',
                    fields: [
                        {
                            fieldName: 'disposals',
                            fieldLabel: 'label.stats.disposals',
                            fieldLabelShort: 'label.stats.disposals.short',
                            sortFunction: 'numberCompare',
                            description: 'label.disposals.description',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.disposals',
                                season: 'stats.averages.disposals'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'kicks',
                            fieldLabel: 'label.stats.kicks',
                            fieldLabelShort: 'label.stats.kicks.short',
                            description: 'label.kicks.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.kicks',
                                season: 'stats.averages.kicks'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'handballs',
                            fieldLabel: 'label.stats.handballs',
                            fieldLabelShort: 'label.stats.handballs.short',
                            description: 'label.handballs.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.handballs',
                                season: 'stats.averages.handballs'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'inside50s',
                            fieldLabel: 'label.stats.inside50',
                            fieldLabelShort: 'label.stats.inside50s.short',
                            description: 'label.inside50s.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.inside50s',
                                season: 'stats.averages.inside50s'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'disposalEfficiency',
                            fieldLabel: 'label.stats.disposalEfficiency',
                            fieldLabelShort:
                                'label.stats.disposalEfficiency.short',
                            description: 'label.disposalEfficiency.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.disposalEfficiency',
                                season: 'stats.averages.disposalEfficiency'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'clangers',
                            fieldLabel: 'label.stats.clangers',
                            fieldLabelShort: 'label.stats.clangers.short',
                            description: 'label.clangers.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.clangers',
                                season: 'stats.averages.clangers'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'rebound50s',
                            fieldLabel: 'label.stats.rebound50s',
                            fieldLabelShort: 'label.stats.rebound50s.short',
                            description: 'label.rebound50s.description',
                            sortFunction: numberCompare,
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.rebound50s',
                                season: 'stats.averages.rebound50s'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'metresGained',
                            fieldLabel: 'label.stats.metresGained',
                            fieldLabelShort: 'label.stats.metresGained.short',
                            description: 'label.metresGained.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.metresGained',
                                season: 'stats.averages.metresGained'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'effectiveKicks',
                            fieldLabel: 'label.stats.effectiveKicks',
                            fieldLabelShort: 'label.stats.effectiveKicks.short',
                            description: 'label.effectiveKicks.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.effectiveKicks',
                                season: 'stats.averages.effectiveKicks'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'kickEfficiency',
                            fieldLabel: 'label.stats.kickEffeciency',
                            fieldLabelShort: 'label.stats.kickEffeciency.short',
                            description: 'label.kickEfficiency.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.kickEfficiency',
                                season: 'stats.averages.kickEfficiency'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'kickToHandballRatio',
                            fieldLabel: 'label.stats.kickToHandballRatio',
                            fieldLabelShort:
                                'label.stats.kickToHandballRatio.short',
                            description:
                                'label.kickToHandballRatio.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.kickToHandballRatio',
                                season: 'stats.averages.kickToHandballRatio'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'effectiveDisposals',
                            fieldLabel: 'label.stats.effectiveDisposals',
                            fieldLabelShort:
                                'label.stats.effectiveDisposals.short',
                            description: 'label.effectiveDisposals.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.effectiveDisposals',
                                season: 'stats.averages.effectiveDisposals'
                            },
                            parentSelector: 'players|lists'
                        }
                    ]
                },
                {
                    name: 'Possessions',
                    label: 'Possessions',
                    fields: [
                        {
                            fieldName: 'contestedPossessions',
                            fieldLabel: 'label.stats.contestedPossessions',
                            fieldLabelShort:
                                'label.stats.contestedPossessions.short',
                            description:
                                'label.contestedPossessions.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.contestedPossessions',
                                season: 'stats.averages.contestedPossessions'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'uncontestedPossessions',
                            fieldLabel: 'label.stats.uncontestedPossessions',
                            fieldLabelShort:
                                'label.stats.uncontestedPossessions.short',
                            description:
                                'label.uncontestedPossessions.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.uncontestedPossessions',
                                season: 'stats.averages.uncontestedPossessions'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'intercepts',
                            fieldLabel: 'label.stats.intercepts',
                            fieldLabelShort: 'label.stats.intercepts.short',
                            description: 'label.intercepts.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.intercepts',
                                season: 'stats.averages.intercepts'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'turnovers',
                            fieldLabel: 'label.stats.turnovers',
                            fieldLabelShort: 'label.stats.turnovers.short',
                            description: 'label.turnovers.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.turnovers',
                                season: 'stats.averages.turnovers'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'contestedPossessionRate',
                            fieldLabel: 'label.stats.contestedPossessionRate',
                            fieldLabelShort:
                                'label.stats.contestedPossessionRate.short',
                            description:
                                'label.contestedPossessionRate.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.contestedPossessionRate',
                                season: 'stats.averages.contestedPossessionRate'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'groundBallGets',
                            fieldLabel: 'label.stats.groundBallGets',
                            fieldLabelShort: 'label.stats.groundBallGets.short',
                            description: 'label.groundBallGets.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.groundBallGets',
                                season: 'stats.averages.groundBallGets'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'f50GroundBallGets',
                            fieldLabel: 'label.stats.f50GroundBallGets',
                            fieldLabelShort:
                                'label.stats.f50GroundBallGets.short',
                            description: 'label.f50GroundBallGets.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.f50GroundBallGets',
                                season: 'stats.averages.f50GroundBallGets'
                            },
                            parentSelector: 'players|lists'
                        }
                    ]
                },
                {
                    name: 'Stoppages',
                    label: 'Stoppages',
                    fields: [
                        {
                            fieldName: 'hitouts',
                            fieldLabel: 'label.stats.hitouts',
                            fieldLabelShort: 'label.stats.hitouts.short',
                            description: 'label.hitouts.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.hitouts',
                                season: 'stats.averages.hitouts'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'centreClearances',
                            fieldLabel: 'label.stats.centreClearances',
                            fieldLabelShort:
                                'label.stats.centreClearances.short',
                            description: 'label.centreClearances.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.clearances.centreClearances',
                                season: 'stats.averages.clearances.centreClearances'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'stoppageClearances',
                            fieldLabel: 'label.stats.stoppageClearances',
                            fieldLabelShort:
                                'label.stats.stoppageClearances.short',
                            description: 'label.stoppageClearances.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.clearances.stoppageClearances',
                                season: 'stats.averages.clearances.stoppageClearances'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'totalClearances',
                            fieldLabel: 'label.stats.totalClearances',
                            fieldLabelShort:
                                'label.stats.totalClearances.short',
                            description: 'label.totalClearances.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.clearances.totalClearances',
                                season: 'stats.averages.clearances.totalClearances'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'hitoutsToAdvantage',
                            fieldLabel: 'label.stats.hitoutsToAdvantage',
                            fieldLabelShort:
                                'label.stats.hitoutsToAdvantage.short',
                            description: 'label.hitoutsToAdvantage.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.hitoutsToAdvantage',
                                season: 'stats.averages.hitoutsToAdvantage'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'hitoutWinPercentage',
                            fieldLabel: 'label.stats.hitoutWinPercentage',
                            fieldLabelShort:
                                'label.stats.hitoutWinPercentage.short',
                            description:
                                'label.hitoutWinPercentage.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.hitoutWinPercentage',
                                season: 'stats.averages.hitoutWinPercentage'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'hitoutToAdvantageRate',
                            fieldLabel: 'label.stats.hitoutToAdvantageRate',
                            fieldLabelShort:
                                'label.stats.hitoutToAdvantageRate.short',
                            description:
                                'label.hitoutToAdvantageRate.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.hitoutToAdvantageRate',
                                season: 'stats.averages.hitoutToAdvantageRate'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'ruckContests',
                            fieldLabel: 'label.stats.ruckContests',
                            fieldLabelShort: 'label.stats.ruckContests.short',
                            description: 'label.ruckContests.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.ruckContests',
                                season: 'stats.averages.ruckContests'
                            },
                            parentSelector: 'players|lists'
                        }
                    ]
                },
                {
                    name: 'Marks',
                    label: 'Marks',
                    fields: [
                        {
                            fieldName: 'marks',
                            fieldLabel: 'label.stats.marks',
                            fieldLabelShort: 'label.stats.marks.short',
                            description: 'label.marks.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.marks',
                                season: 'stats.averages.marks'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'marksInside50',
                            fieldLabel: 'label.stats.marksInside50',
                            fieldLabelShort: 'label.stats.marksInside50.short',
                            description: 'label.marksInside50.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.marksInside50',
                                season: 'stats.averages.marksInside50'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'marksOnLead',
                            fieldLabel: 'label.stats.marksOnLead',
                            fieldLabelShort: 'label.stats.marksOnLead.short',
                            sortFunction: 'numberCompare',
                            description: 'label.marksOnLead.description',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.marksOnLead',
                                season: 'stats.averages.marksOnLead'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'interceptMarks',
                            fieldLabel: 'label.stats.interceptMarks',
                            fieldLabelShort: 'label.stats.interceptMarks.short',
                            description: 'label.interceptMarks.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.interceptMarks',
                                season: 'stats.averages.interceptMarks'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'contestedMarks',
                            fieldLabel: 'label.stats.contestedMarks',
                            fieldLabelShort: 'label.stats.contestedMarks.short',
                            description: 'label.contestedMarks.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.contestedMarks',
                                season: 'stats.averages.contestedMarks'
                            },
                            parentSelector: 'players|lists'
                        }
                    ]
                },
                {
                    name: 'Scoring',
                    label: 'Scoring',
                    fields: [
                        {
                            fieldName: 'goals',
                            fieldLabel: 'label.stats.goals',
                            fieldLabelShort: 'label.stats.goals.short',
                            description: 'label.goals.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.goals',
                                season: 'stats.averages.goals'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'behinds',
                            fieldLabel: 'label.stats.behinds',
                            fieldLabelShort: 'label.stats.behinds.short',
                            description: 'label.behinds.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.behinds',
                                season: 'stats.averages.behinds'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'goalAssists',
                            fieldLabel: 'label.stats.goalAssists',
                            fieldLabelShort: 'label.stats.goalAssists.short',
                            description: 'label.goalAssists.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.goalAssists',
                                season: 'stats.averages.goalAssists'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'goalAccuracy',
                            fieldLabel: 'label.stats.goalAccuracy',
                            fieldLabelShort: 'label.stats.goalAccuracy.short',
                            description: 'label.goalAccuracy.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.goalAccuracy',
                                season: 'stats.averages.goalAccuracy'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'shotsAtGoal',
                            fieldLabel: 'label.stats.shotsAtGoal',
                            fieldLabelShort: 'label.stats.shotsAtGoal.short',
                            description: 'label.shotsAtGoal.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.shotsAtGoal',
                                season: 'stats.averages.shotsAtGoal'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'scoreInvolvements',
                            fieldLabel: 'label.stats.scoreInvolvements',
                            fieldLabelShort:
                                'label.stats.scoreInvolvements.short',
                            description: 'label.scoreInvolvements.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.scoreInvolvements',
                                season: 'stats.averages.scoreInvolvements'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'scoreLaunches',
                            fieldLabel: 'label.stats.scoreLaunches',
                            fieldLabelShort: 'label.stats.scoreLaunches.short',
                            description: 'label.scoreLaunches.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.scoreLaunches',
                                season: 'stats.averages.scoreLaunches'
                            },
                            parentSelector: 'players|lists'
                        }
                    ]
                },
                {
                    name: 'Defense',
                    label: 'Defense',
                    fields: [
                        {
                            fieldName: 'tackles',
                            fieldLabel: 'label.stats.tackles',
                            fieldLabelShort: 'label.stats.tackles.short',
                            description: 'label.tackles.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.tackles',
                                season: 'stats.averages.tackles'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'tacklesInside50',
                            fieldLabel: 'label.stats.tacklesInside50',
                            fieldLabelShort:
                                'label.stats.tacklesInside50.short',
                            description: 'label.tacklesInside50.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.tacklesInside50',
                                season: 'stats.averages.tacklesInside50'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'pressureActs',
                            fieldLabel: 'label.stats.pressureActs',
                            fieldLabelShort: 'label.stats.pressureActs.short',
                            description: 'label.pressureActs.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.pressureActs',
                                season: 'stats.averages.pressureActs'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'defHalfPressureActs',
                            fieldLabel: 'label.stats.defHalfPressureActs',
                            fieldLabelShort:
                                'label.stats.defHalfPressureActs.short',
                            description:
                                'label.defHalfPressureActs.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.defHalfPressureActs',
                                season: 'stats.averages.defHalfPressureActs'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'spoils',
                            fieldLabel: 'label.stats.spoils',
                            fieldLabelShort: 'label.stats.spoils.short',
                            description: 'label.spoils.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.spoils',
                                season: 'stats.averages.spoils'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'contestDefOneOnOnes',
                            fieldLabel: 'label.stats.contestDefOneOnOnes',
                            fieldLabelShort:
                                'label.stats.contestDefOneOnOnes.short',
                            description:
                                'label.contestDefOneOnOnes.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.contestDefOneOnOnes',
                                season: 'stats.averages.contestDefOneOnOnes'
                            },
                            parentSelector: 'players|lists'
                        },
                        {
                            fieldName: 'contestDefLosses',
                            fieldLabel: 'label.stats.contestDefLosses',
                            fieldLabelShort:
                                'label.stats.contestDefLosses.short',
                            description: 'label.contestDefLosses.description',
                            sortFunction: 'numberCompare',
                            apiMap: { match: 0, season: 1 },
                            displayByDefault: false,
                            selector: {
                                match: 'totals.contestDefLosses',
                                season: 'stats.averages.contestDefLosses'
                            },
                            parentSelector: 'players|lists'
                        }
                    ]
                }
            ],
            displayByDefault: [''],
            jsonApis: [
                {
                    api: this.getMatchStatsEndpoint(),
                    type: 'match',
                    token: true
                },
                {
                    api: this.getSeasonAvgStatsEndpoint(),
                    type: 'season',
                    token: true
                }
            ],
            defaultJsonApi: 'match',
            headers: {},
            sortBy: {
                fieldName: 'givenName',
                direction: 'ASC'
            },
            infoText: 'Your custom player stats',
            tabType: 'match',
            rowIdentifier: {
                parentSelector: 'players|lists',
                selector: {
                    match: 'playerId',
                    season: 'player.playerId'
                },
                apiMap: 0
            },
            matchId: this.props.data.matchPid,
            homeTeamId: this.props.data.homeTeamId,
            awayTeamId: this.props.data.awayTeamId,
            homeTeamAbbr: this.props.data.homeAbbr,
            awayTeamAbbr: this.props.data.awayAbbr,
            updateManageFieldsPosition: this.updateManageFieldsPosition,
            hasType: true
        };

        this.props.addCustomTable(tableConfig);

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    getSeasonAvgStatsEndpoint = () => {
        const compId = this.props.data.compId;
        const homeTeamId = this.props.data.homeTeamId;
        const awayTeamId = this.props.data.awayTeamId;

        return `${PULSE.app.environment.cfsApi}/statsCentre/players?competitionId=${compId}&teamIds=${homeTeamId},${awayTeamId}`;
    };

    getMatchStatsEndpoint = () => {
        const roundId = this.props.data.roundId;
        const homeTeamId = this.props.data.homeTeamId;
        const awayTeamId = this.props.data.awayTeamId;

        return `${PULSE.app.environment.statsPro}/playersStats/rounds/${roundId}?teamId=${homeTeamId},${awayTeamId}`;
    };

    componentDidUpdate() {
        if (
            this.tableRef &&
            this.tableRef.current &&
            this.tableRef.current.customTableRef &&
            this.tableRef.current.customTableRef.current
        ) {
            new PULSE.app.PlayerHeadshot(
                this.tableRef.current.customTableRef.current,
                {
                    compSeasonProviderId: this.props.data.compId
                }
            );
        }
    }

    checkDataset() {
        if (
            !this.props.customTable.dataJson ||
            !this.props.customTable.dataJson.length
        ) {
            return false;
        }
        return this.props.customTable.dataJson.find((dataset) => {
            if (typeof dataset === 'undefined') {
                return false;
            }
            return (
                dataset.type === this.props.customTable.config.defaultJsonApi
            );
        });
    }

    isLoading() {
        return !this.props.customTable || !this.checkDataset();
    }

    render() {
        // check for fields
        const isLoading = this.isLoading(this);

        // const tableAndTabType = this.props.customTable && this.props.customTable.tabTable && this.props.customTable.tabType && this.props.customTable.tabTable[ this.props.customTable.tabType ];
        // const customTable = tableAndTabType ? this.props.customTable.tabTable[ this.props.customTable.tabType ] : null;
        const fields = this.props.customTable
            ? this.props.customTable.fields
            : null;
        const fieldGroups = this.props.customTable
            ? this.props.customTable.fieldGroups
            : null;
        const sortBy = this.props.customTable
            ? this.props.customTable.sortBy
            : null;
        const config = this.props.customTable
            ? this.props.customTable.config
            : null;

        return (
            <DefaultTable
                ref={this.tableRef}
                isLoading={isLoading}
                {...this.props}
                customTable={this.props.customTable}
                fields={fields}
                tableFieldGroups={fieldGroups}
                sortBy={sortBy}
                config={config}
            />
        );
    }
}

export default PlayerStatsTable;
