import React from 'react';
import { useDispatch } from 'react-redux';

import { getTranslation } from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const ManageStats = () => {
    const dispatch = useDispatch();

    const toggleModal = (tableName) =>
        dispatch(
            PULSE.app.redux.actions.customtable.TOGGLE_MODAL.request({
                tableName
            })
        );

    return (
        <button
            className="filter-button filter-button--manage-stats"
            aria-label={getTranslation('label.customTable.manageStatsButton')}
            onClick={() => toggleModal('ladder')}
        >
            <SvgIcon icon="manage" className="filter-button__icon" />
            <span>{getTranslation('label.customTable.manageStatsButton')}</span>
        </button>
    );
};
