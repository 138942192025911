import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { navSelector } from 'store/modules/competition-nav/selectors';
import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';
import { SwitchInput } from '../../SwitchInput';
import { getTranslation } from 'common/react/utils/translations';

export const OtherMatchesToggle = ({ style }) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);

    return (
        <div className="competition-nav__other-matches-toggle" style={style}>
            <SwitchInput
                id="other-matches-toggle"
                label={getTranslation('label.show-other-matches')}
                isChecked={nav.otherMatches.isActive}
                onChange={(isActive) => {
                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'otherMatches',
                            value: {
                                isActive: isActive
                            }
                        })
                    );
                }}
            />
        </div>
    );
};

OtherMatchesToggle.propTypes = {
    style: PropTypes.object
};
