import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { BroadcasterItem } from './BroadcasterItem';
import { useGetMatchEventsQuery } from 'common/store/modules/api/aflm-content-api';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';

// in order of display
const BROADCASTERS = ['Channel 7', 'Foxtel', 'Kayo'];

const filterAndSort = (items) => {
    const filtered = items.filter((item) => BROADCASTERS.includes(item.name));
    const sorted = filtered.sort(
        (a, b) => BROADCASTERS.indexOf(a.name) - BROADCASTERS.indexOf(b.name)
    );
    return sorted;
};

export const Broadcasters = ({ matchPid, roundNumber, inView }) => {
    const nav = useSelector(navSelector);
    const selectedRegion = useSingleSiteSetting('region');

    const { data } = useGetMatchEventsQuery(
        {
            competition: nav.competition.id,
            compseason: nav.season.id,
            round: roundNumber,
            pageSize: 100
        },
        {
            skip: !inView
        }
    );

    const matchEvent = data?.content?.find(
        (item) => matchPid === item.contentReference.providerId
    );
    let broadcasters = [];
    let orderedBroadcasters = [];

    if (matchEvent) {
        broadcasters = PULSE.app.common.extractBroadcastersFromMatchEvent(
            matchEvent,
            selectedRegion?.id
        );
    }

    if (broadcasters) {
        orderedBroadcasters = filterAndSort(broadcasters);
    }

    return orderedBroadcasters?.length ? (
        <div className="fixtures__broadcaster-container">
            {orderedBroadcasters.map((broadcaster) => {
                return (
                    <BroadcasterItem
                        broadcaster={broadcaster}
                        key={broadcaster.id}
                    />
                );
            })}
        </div>
    ) : (
        <></>
    );
};

Broadcasters.propTypes = {
    matchPid: PropTypes.any.isRequired,
    roundNumber: PropTypes.number.isRequired,
    inView: PropTypes.bool
};
