import React from 'react';

import DefaultTableHeadRenderer from './DefaultTableHeadRenderer';
import MultipleTableHeadRenderer from '../MultipleTableHeadRenderer';

const getHeadRenderer = (headRendererName) => {
    switch (headRendererName) {
        case 'MultipleTableHeadRenderer':
            return MultipleTableHeadRenderer;
        default:
            return DefaultTableHeadRenderer;
    }
};

const TableHead = ({
    field,
    multipleTableIndex,
    tableName,
    sortBy,
    handleClick,
    getTranslation,
    lastColumn,
    lastColumnRef
}) => {
    const tableHeaderClass = field.tableHeadCustomClass
        ? field.tableHeadCustomClass
        : '';

    const noDescriptionClass =
        field.description &&
        getTranslation(field.description) !== field.description
            ? ''
            : 'custom-table__header-cell--no-description';

    let tableHeadMarkup = '';

    if (field.customTableHeadRenderer) {
        // use the custom renderer; the 2nd argument can also be used to pass custom props
        tableHeadMarkup = React.createElement(
            getHeadRenderer(field.customTableHeadRenderer),
            {
                field: field,
                multipleTableIndex: multipleTableIndex,
                tableName: tableName,
                sortBy: sortBy,
                handleClick: handleClick,
                getTranslation: getTranslation,
                lastColumn: lastColumn,
                lastColumnRef: lastColumnRef
            }
        );
    } else {
        // use the default renderer
        tableHeadMarkup = (
            <DefaultTableHeadRenderer
                className={`custom-table__header-cell ${tableHeaderClass} ${noDescriptionClass}`}
                field={field}
                multipleTableIndex={multipleTableIndex}
                tableName={tableName}
                sortBy={sortBy}
                handleClick={handleClick}
                getTranslation={getTranslation}
                lastColumn={lastColumn}
                lastColumnRef={lastColumnRef}
            />
        );
    }

    return tableHeadMarkup;
};

export default TableHead;
