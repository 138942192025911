import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @callback onChange
 * @param {any} value - The selected value.
 */

/**
 * @typedef {object} Option
 * @property {string} label
 * @property {any} value
 * @property {boolean} [disabled]
 * @property
 */

/**
 * A toggle UI component for a set of radio buttons.
 *
 * @param {object} props - Props.
 * @param {string} props.id - ID.
 * @param {any} props.value - The selected value.
 * @param {Option[]} props.options - The available values
 *    to choose from.
 * @param {onChange} props.onChange - Callback function executed when the
 *    selected option changes.
 * @returns {JSX.Element} Component.
 */
export const ToggleInput = (props) => {
    const [value, setValue] = useState(props.value ?? props.options[0]);
    const toggleInputRef = useRef();

    const onChange = (option) => {
        setValue(option);
        props.onChange(option);
    };

    useLayoutEffect(() => {
        onChange(props.value);
    }, [props.value]);

    return (
        <div className="toggle-input" ref={toggleInputRef}>
            {props.options.map((option, index) => {
                // @ts-ignore
                const isSelected = _.isEqual(value, option.value);

                return (
                    <button
                        key={`${props.id}-${index}`}
                        className={classNames('toggle-input__button', {
                            'toggle-input__button--selected': isSelected
                        })}
                        tabIndex={0}
                        onClick={() => {
                            toggleInputRef?.current.dispatchEvent(
                                new Event(
                                    PULSE.app.common.CONSTANTS.EVENTS.INTERACTION.CHANGE,
                                    {
                                        bubbles: true
                                    }
                                )
                            );
                            onChange(option.value);
                        }}
                        disabled={!!option.disabled}
                    >
                        <span className="toggle-input__button-text">
                            {option.label}
                        </span>
                    </button>
                );
            })}
        </div>
    );
};

ToggleInput.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.any,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired
        })
    ).isRequired,
    onChange: PropTypes.func.isRequired
};
