import React, { createContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
    useGetCompSeasonQuery,
    useGetMatchesQuery
} from 'store/modules/api/afl-api';

import { EmptyState } from 'common/react/components/EmptyState';

import { FixturesItem } from './FixturesItem';
import { FixturesSkeleton } from './FixturesSkeleton';
import { FixturesLegend } from './FixturesLegend';
import { getTranslation } from 'common/react/utils/translations';
import { useGroupMatches } from 'common/react/hooks/use-group-matches';
import { navSelector } from 'store/modules/competition-nav/selectors';

const checkIfBroadcasterGuide = (providerId) => {
    const abbr = PULSE.app.common.match.getCompAbbr(providerId).toUpperCase();

    const translationString = getTranslation(`label.broadcastGuideURL.${abbr}`);

    if (translationString !== `label.broadcastGuideURL.${providerId}`) {
        return translationString;
    }

    return false;
};

export const FixtureCountContext = createContext('');

const { TEAM_IDS } = window.PULSE.app.common.CONSTANTS;

export const FixturesList = ({
    competitionId,
    compSeason,
    broadcasterPromo,
    scrollToRound,
    setScrollToRound,
    roundNumber
}) => {
    const nav = useSelector(navSelector);

    // check guide translation exists
    const broadcastGuideUrl =
        checkIfBroadcasterGuide(compSeason.providerId) ?? '';

    const compAbbreviation = PULSE.app.common.match.getCompAbbr(
        compSeason.providerId
    );

    const teamId = TEAM_IDS[compAbbreviation];

    const { matches = [], isMatchesFetching } = useGetMatchesQuery(
        {
            pageSize: 300,
            competitionId: competitionId,
            compSeasonId: compSeason.id,
            teamId: teamId,
            roundNumber: roundNumber
        },
        {
            selectFromResult: ({ data, isFetching }) => ({
                matches:
                    data &&
                    (nav?.homeAway === 'home'
                        ? data.filter((item) =>
                              Object.values(TEAM_IDS).includes(
                                  `${item?.home?.team.id}`
                              )
                          )
                        : nav?.homeAway === 'away'
                        ? data.filter((item) =>
                              Object.values(TEAM_IDS).includes(
                                  `${item.away.team.id}`
                              )
                          )
                        : data),
                isMatchesFetching: isFetching
            })
        }
    );

    const matchItems = useGroupMatches(matches);

    // Add index property for tracking
    const formattedMatches = matches.map((item, index) =>
        Object.assign({}, item, { index: index + 1 })
    );

    const isConferenceSeason =
        formattedMatches &&
        formattedMatches.some(
            (match) => match?.home?.conference || match?.away?.conference
        );

    const { roundsData } = useGetCompSeasonQuery(
        {
            id: nav.season.id
        },
        {
            selectFromResult: ({ data }) => ({
                roundsData: data?.[0] ? data[0].rounds : []
            })
        }
    );

    if (isMatchesFetching) {
        return <FixturesSkeleton />;
    }

    if (!roundsData || !roundsData.length) {
        return <EmptyState cssClass="competition-nav__empty-state" />;
    }

    // find first live or pre-match fixture
    let firstLiveOrUpcomingFixture = {};
    firstLiveOrUpcomingFixture = [...formattedMatches]
        .reverse()
        .find(
            (match) =>
                PULSE.app.common.match.getMatchStatus(match.status) ===
                PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE
        );
    if (!firstLiveOrUpcomingFixture) {
        firstLiveOrUpcomingFixture = formattedMatches.find(
            (match) =>
                PULSE.app.common.match.getMatchStatus(match.status) ===
                PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING
        );
    }

    return (
        <>
            <FixtureCountContext.Provider value={formattedMatches?.length}>
                {roundsData.length
                    ? roundsData.map((round, index) => (
                          <FixturesItem
                              key={round.id}
                              round={round}
                              items={matchItems[round.roundNumber]}
                              broadcastGuideUrl={broadcastGuideUrl}
                              broadcasterPromo={broadcasterPromo}
                              firstLiveOrUpcomingFixture={
                                  firstLiveOrUpcomingFixture?.id
                              }
                              scrollToRound={scrollToRound}
                              setScrollToRound={setScrollToRound}
                              teamId={teamId}
                              roundNumber={roundNumber}
                              index={index}
                          />
                      ))
                    : ''}

                {matchItems[PULSE.app.common.CONSTANTS.UNCONFIRMED_GROUP]
                    ?.length ? (
                    <FixturesItem
                        items={
                            matchItems[
                                PULSE.app.common.CONSTANTS.UNCONFIRMED_GROUP
                            ]
                        }
                        broadcastGuideUrl={broadcastGuideUrl}
                        broadcasterPromo={broadcasterPromo}
                        firstLiveOrUpcomingFixture={
                            firstLiveOrUpcomingFixture?.id
                        }
                        scrollToRound={scrollToRound}
                        setScrollToRound={setScrollToRound}
                        teamId={teamId}
                    />
                ) : (
                    <></>
                )}

                {isConferenceSeason && <FixturesLegend />}
            </FixtureCountContext.Provider>
        </>
    );
};

FixturesList.propTypes = {
    competitionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    compSeason: PropTypes.object,
    broadcasterPromo: PropTypes.object,
    scrollToRound: PropTypes.bool,
    setScrollToRound: PropTypes.func,
    roundNumber: PropTypes.number
};
