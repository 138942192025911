import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { StatsNavItem, TeamsNavItem } from './nav-items';
import { dataTypeOptions, DataTypeToggle, Heading } from './header';
import { PlayerStatsTable } from './table';
import { ManageStatsButton } from './nav-items/ManageStatsButton';
import {
    playerStatsNavSelector,
    playerStatsDataTypeSelector
} from 'store/modules/match-centre/player-stats/selectors';
import { useFiltersTracking } from 'common/react/hooks/use-filters-tracking';

export const PlayerStats = ({
    pid,
    compPid,
    homePid,
    awayPid,
    homeNickname,
    awayNickname,
    title
}) => {
    const dispatch = useDispatch();
    const status = useSelector((state) =>
        PULSE.app.redux.selectors.match.status(state, pid)
    );
    const matchStatus = PULSE.app.common.match.getMatchStatus(status);
    const nav = useSelector(playerStatsNavSelector);
    const playerStatsRef = useRef();

    const config = {
        matchId: pid,
        competitionId: compPid,
        homeTeamId: homePid,
        awayTeamId: awayPid
    };

    useEffect(() => {
        if (matchStatus) {
            dispatch(
                PULSE.app.redux.actions.playerstats.ADD_MATCH_PLAYER_STATS.request(
                    { config }
                )
            );
            dispatch(
                PULSE.app.redux.actions.playerstats.ADD_SEASON_PLAYER_STATS.request(
                    { config }
                )
            );
        }
    }, [matchStatus]);

    /**
     * custom hook providing tracking of filters
     */
    const dataType = useSelector(playerStatsDataTypeSelector);
    useFiltersTracking({
        title,
        filtersState: {
            statsTeams: nav.teams,
            stats: nav.stats,
            dataType
        },
        options: {
            dataTypes: dataTypeOptions
        },
        filtersFields: ['stats', 'statsTeams', 'dataType'],
        filtersRef: playerStatsRef
    });

    return (
        <div ref={playerStatsRef}>
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <StatsNavItem title={title} />
                        <TeamsNavItem
                            homeNickname={homeNickname}
                            awayNickname={awayNickname}
                            title={title}
                        />
                    </StatsSection.NavItems>
                    {nav.stats === 'My Stats' && (
                        <StatsSection.AdditionalNavItems>
                            <ManageStatsButton />
                        </StatsSection.AdditionalNavItems>
                    )}
                </StatsSection.Nav>
                <StatsSection.Content>
                    <StatsSection.ContentHeader>
                        <StatsSection.ContentHeaderHeading>
                            <Heading />
                        </StatsSection.ContentHeaderHeading>
                        <StatsSection.ContentHeaderOptions>
                            <DataTypeToggle />
                        </StatsSection.ContentHeaderOptions>
                    </StatsSection.ContentHeader>
                    <StatsSection.ContentBody>
                        <PlayerStatsTable
                            matchId={pid}
                            homePid={homePid}
                            awayPid={awayPid}
                            homeNickname={homeNickname}
                            awayNickname={awayNickname}
                        />
                    </StatsSection.ContentBody>
                </StatsSection.Content>
            </StatsSection.Main>
        </div>
    );
};

PlayerStats.propTypes = {
    pid: PropTypes.string.isRequired,
    compPid: PropTypes.string.isRequired,
    homePid: PropTypes.string.isRequired,
    awayPid: PropTypes.string.isRequired,
    homeNickname: PropTypes.string.isRequired,
    awayNickname: PropTypes.string.isRequired,
    title: PropTypes.string
};
